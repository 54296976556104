import React from "react";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
} from "semantic-ui-react";
import Layout from "../components/Layout";

const Heading = () => (
  <div style={{ position: 'relative' }}>
    <div style={{ background: 'rgb(30,45,179)', paddingBottom: '100px' }}>
      <Container style={{color: '#FFF'}}>
        <Grid columns={2} stackable>
          <Grid.Row style={{margin: '2em 0'}}>
            <Grid.Column verticalAlign="middle">
              <Header
                as="h1"
                inverted
                content="Biometrics & Passwordless Authentication for Salesforce"
                style={{
                  fontSize: "3em",
                  fontWeight: "normal",
                  marginBottom: 0,
                  marginTop: "1.5em",
                }}
              />
              <Header
                as="h2"
                inverted
                content="Make user authentication a delightful experience"
                style={{
                  fontSize: "1.7em",
                  fontWeight: "normal",
                  marginTop: "1.5em",
                }}
              />
              <p style={{textAlign: "center"}}>
                <a
                  href="https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3A00000GE6liUAD"
                  target="_blank"
                >
                  <Button inverted>Try now</Button>
                </a>
                <Header.Subheader style={{ margin: '8px'}}>Free 30 Day Trial</Header.Subheader>
              </p>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <Image src="/images/authenticator-chooser-draw.jpg" alt="Custom Authentication Experience"
                bordered
                style={{
                  boxShadow: "0 1px 2px 0 rgba(34,36,38,.15)",
                  padding: "16px",
                  margin: 'auto'
                }}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
    <div className="wave-divider">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
      </svg>
    </div>
  </div>
);

const Features = () => (
  <Container>
    <Grid columns="equal" stackable>
      <Grid.Row textAlign="center" verticalAlign="middle">
        <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em" }}>
          <Header as="h3" style={{ fontSize: "2em" }}>
            <Icon name="users" />
          </Header>
          <Header as="h2">Secure your customers and employees</Header>
        </Grid.Column>
        <Grid.Column textAlign="center">
          <p>
            Allow your customers to have a seamless experience with Single Sign
            On and avoid the password hell across your ecosystem
          </p>
        </Grid.Column>
      </Grid.Row>
      <Divider />
      <Grid.Row textAlign="center" verticalAlign="middle">
        <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em" }}>
          <Header as="h3" style={{ fontSize: "2em" }}>
            <Icon name="code" />
          </Header>
          <Header as="h2">Improve your developer experience</Header>
        </Grid.Column>
        <Grid.Column textAlign="center">
          <p>
            Developers can start building quickly using standard oAuth, OpenID
            Connect and JWTs
          </p>
        </Grid.Column>
      </Grid.Row>
      <Divider />
      <Grid.Row textAlign="center" verticalAlign="middle">
        <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em" }}>
          <Header as="h3" style={{ fontSize: "2em" }}>
            <Icon name="lock" />
          </Header>
          <Header as="h2">Improve your API security</Header>
        </Grid.Column>
        <Grid.Column textAlign="center">
          <p>
            Whether it's a machine to machine communication, a 3rd party
            consumer, set up the correct policies for each API
          </p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
);


export default () => (
  <Layout section="home" title="CYM-Identity">
    <Heading/>
    <Segment style={{ padding: "2em" }} vertical>
      <Features />
    </Segment>
  </Layout>
);
